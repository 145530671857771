<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">每日打卡配置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="打卡任务名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">打卡任务名称:</span>
              <el-input v-model="retrievalData.taskName" type="text" size="small" placeholder="请输入打卡任务名称" clearable />
            </div>
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-select clearable v-model="retrievalData.bankId" size="small" filterable remote :remote-method="retrievalDataSearchCopmp"
                placeholder="请至少输入两个字搜索">
                <el-option size="small" v-for="item in retrievalDataQuestionBankList" :key="item.bankId" :label="item.bankName"
                  :value="item.bankId"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round icon="el-icon-plus" @click="cAddSeeDailyClockIn()">新建打卡任务</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="打卡任务名称" align="left" show-overflow-tooltip prop="taskName" minWidth="200px" />
              <el-table-column label="题库名称" align="left" show-overflow-tooltip prop="bankName" minWidth="200px" />
              <el-table-column label="抽题数量" align="left" show-overflow-tooltip prop="questionNum" minWidth="100" />
              <el-table-column label="打卡人次" align="left" show-overflow-tooltip prop="signNum" minWidth="100" />
              <el-table-column label="打卡截止日期" align="left" show-overflow-tooltip minWidth="200px">
                <template slot-scope="scope"> 
                  {{
                    scope.row.endDate | momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="状态" align="left" show-overflow-tooltip minWidth="100px">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == '00'">未开始</span>
                  <span v-else-if="scope.row.status == '10'">进行中</span>
                  <span v-else-if="scope.row.status == '20'">已结束</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" align="left" show-overflow-tooltip minWidth="150px">
                <template slot-scope="scope">
                  {{
                    scope.row.createTimeStr | momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="180px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="cAddSeeDailyClockIn(scope.row)">查看</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="startEnd(scope.row)" v-if="scope.row.status == '00'">开始</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="startEnd(scope.row)" v-if="scope.row.status == '10'">结束</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="goSeeDailyClockInInfo(scope.row)">查看打卡详情</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 新增/查看 打卡任务 -->
    <el-dialog :title="addSeeDailyClockIn.dialogVisibleTitle" :visible.sync="addSeeDailyClockIn.dialogVisible" width="30%" center :before-close="addSeeDailyClockIn_2">
      <el-form ref="addSeeDailyClockIn" :model="addSeeDailyClockIn" label-width="120px" :rules="addSeeDailyClockInRules" :disabled="addSeeDailyClockIn.addSeeDailyClockInTyepe == 2">
        <el-form-item label="打卡任务名称" prop="taskName">
          <el-input size="small" v-model="addSeeDailyClockIn.taskName" placeholder="请输入打卡任务名称"></el-input>
        </el-form-item>
        <el-form-item label="选择题库" prop="bankId">
          <el-select clearable v-model="addSeeDailyClockIn.bankId" size="small" filterable remote :remote-method="searchCopmp"
            placeholder="请至少输入两个字搜索">
            <el-option size="small" v-for="item in questionBankList" :key="item.bankId" :label="item.bankName"
              :value="item.bankId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="截止日期" prop="endDate">
          <el-date-picker
            v-model="addSeeDailyClockIn.endDate"
            type="date"
            size="small"
            value-format="yyyy-MM-dd"
            value="yyyy-MM-dd"
            placeholder="请选择截止日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="抽题数量" prop="questionNum">
          <el-input size="small" v-model="addSeeDailyClockIn.questionNum" @keyup.native="addSeeDailyClockIn.questionNum = zF.positiveInteger(addSeeDailyClockIn.questionNum, 2)" placeholder="请输入抽题数量"></el-input>
        </el-form-item>
        <el-form-item style="color: red;">
          注：组合题按照小题数量抽取
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSeeDailyClockIn_2">取 消</el-button>
        <el-button type="primary" @click="addSeeDailyClockIn_1" :disabled="addSeeDailyClockIn.addSeeDailyClockInTyepe == 2">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "inkQuestionBank_dailyClockIn_dailyClockInList",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      // 检索数据
      retrievalData: {
        taskName: "", // 打卡任务名称
        bankId: "", // 题库名称
      },
      // 新增/查看:打卡任务数据
      addSeeDailyClockIn:{
        addSeeDailyClockInTyepe: 1, // 1-新增；2-编辑
        dialogVisible: false, // 弹框状态
        dialogVisibleTitle:"", // 弹框标题
        taskName:"", // 打卡任务名称
        bankId:"", // 题库id
        endDate:"", // 有效期
        questionNum:"", // 抽题数量
      },
      // 题库数据 - 检索用
      retrievalDataQuestionBankList: [],
      // 题库数据
      questionBankList: [],
      // 新增/查看:打卡任务数据校验
      addSeeDailyClockInRules: {
        taskName: [
          { required: true, message: '请输入打卡任务名称', trigger: 'blur' },
        ],
        bankId: [
          { required: true, message: '请选择题库', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '请选择有效期', trigger: 'change' }
        ],
        questionNum: [
          { required: true, message: '请输入抽题数量', trigger: 'blur' }
        ],
      }
    };
  },
  mixins: [List],
  created() {
    
  },
  computed: {
    
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.retrievalData.taskName) {
        params.taskName = this.retrievalData.taskName;
      }
      if (this.retrievalData.bankId) {
        params.bankId = this.retrievalData.bankId;
      }
      this.doFetch({
        url: "/biz/exam/bank/daily/exercise/pageList",
        params,
        pageNum
      }, true, 6);
    },
    // 搜索题库 - 检索用
    retrievalDataSearchCopmp(query){
      if (query.trim().length >= 1) {
        this.$post("/biz/exam/bank/pageList", { bankName: query }, 3000, true, 6).then(
          (res) => {
            this.retrievalDataQuestionBankList = res.data.list;
          }
        );
      } else {
        this.retrievalDataQuestionBankList = [];
      }
    },
    // 搜索题库
    searchCopmp(query) {
      if (query.trim().length >= 1) {
        this.$post("/biz/exam/bank/pageList", { bankName: query }, 3000, true, 6).then(
          (res) => {
            this.questionBankList = res.data.list;
          }
        );
      } else {
        this.questionBankList = [];
      }
    },
    // 搜索题库 - 用id查，回显得时候用这个
    searchCopmpId(bankId){
      this.$post("/biz/exam/bank/examBankList", { bankId }, 3000, true, 6).then(
        (res) => {
          this.questionBankList = res.data;
        }
      );
    },
    // 新增/查看
    cAddSeeDailyClockIn(row) {
      if(row){
        this.addSeeDailyClockIn.addSeeDailyClockInTyepe = 2;
        this.addSeeDailyClockIn.dialogVisibleTitle = '查看打卡任务';
        this.addSeeDailyClockIn.taskName = row.taskName;
        this.addSeeDailyClockIn.bankId = row.bankId;
        this.addSeeDailyClockIn.endDate = row.endDate.replaceAll("/", "-");
        this.addSeeDailyClockIn.questionNum = row.questionNum;
        this.searchCopmpId(row.bankId);
      } else {
        this.addSeeDailyClockIn.dialogVisibleTitle = '新建打卡任务';
      }
      this.addSeeDailyClockIn.dialogVisible = true;
    },
    // 新增/查看:确定
    addSeeDailyClockIn_1(){
      this.$refs['addSeeDailyClockIn'].validate((valid) => {
        if (valid) {
          let cData = { ...this.addSeeDailyClockIn };
          this.$post("/biz/exam/bank/daily/exercise/insert", cData, 3000, true, 6).then(res => {
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.addSeeDailyClockIn_2();
            this.getData();
          });
        } 
      });
    },
    // 新增/查看:取消/关闭
    addSeeDailyClockIn_2(){
      this.$refs['addSeeDailyClockIn'].clearValidate();
      this.addSeeDailyClockIn = this.$options.data().addSeeDailyClockIn;
    },
    // 开始/结束
    startEnd(row){
      this.$post("/biz/exam/bank/daily/exercise/updateStatus", {
        exerciseId: row.exerciseId,
        status:row.status == '00'? '10':'20'
      }, 3000, true, 6).then(res => {
        this.$message({
          type: "success",
          message: row.status == '00'? '您已设置开始':'您已设置结束',
        });
        this.getData(this.pageNum);
      });
    },
    // 查看打卡详情
    goSeeDailyClockInInfo(row){
      this.$router.push({
        path: "/inkQuestionBank/dailyClockIn/dailyClockInInfo",
        query: {
          exerciseId: row.exerciseId
        }
      });
    },
    // 删除
    deleteExaminationPaperList(ruleId) {
      this.$confirm('试卷删除后不可恢复，确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post("/biz/exam/bank/extract/rule/delete", { ruleId }, 3000, true, 6)
          .then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData(this.pageNum);
          })
          .catch(() => {
            return;
          });
      }).catch(() => {
        
      });
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 10;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
};
</script>

<style lang="less" scoped></style>
